html,
body {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    background: #000;
    overflow: hidden;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex: 1;
    flex-flow: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
    justify-content: center;
}

.particles-wrapper {
    flex: auto;
    display: flex;
    flex-flow: column;
    z-index: 1;
    overflow: hidden;
}

@keyframes background-image-anime {
    0%   { opacity: 0; }
    25% { opacity: .2; }
    75% { opacity: .9; }
    85% { opacity: .3; }
    100% { opacity: 0; }
}

.particles-wrapper::after {
    opacity: 0;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center;
    background-image: url("assets/images/backgrounds/tokyo.jpg");
    background-size: cover;
    z-index: -1;
    animation: background-image-anime 10s infinite; /* IE 10+, Fx 29+ */
}

@media only screen and (max-width: 800px) {
    .particles-wrapper::after {
        background-image: url("assets/images/backgrounds/tokyo--small.jpg");
    }

    .particles-wrapper > div {
        display: none;
    }
}

.particles-wrapper::before {
    content: "";
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 100px;
    height: 100px;
    max-height: 25vh;
    max-width: 25vw;;
    background-position: bottom right;
    background-image: url("assets/images/logos/claude-temp-get-ready.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
}

.particles-wrapper > div {
    flex: auto;
}

#root {
    width: 100vw;
    height: 100vh;
    display: flex;
}
